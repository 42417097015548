import(/* webpackMode: "eager" */ "/vercel/path0/app/toast-wrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@akva/icons/lib/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@akva/components/lib/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@akva/profile/lib/colors/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@akva/profile/lib/sizing/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/features/shared/css/layout.css");
import(/* webpackMode: "eager" */ "/vercel/path0/features/shared/css/empty-css-bugfix.css");
import(/* webpackMode: "eager" */ "/vercel/path0/features/shared/css/settings.css");
import(/* webpackMode: "eager" */ "/vercel/path0/features/shared/css/global-styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/features/shared/css/utility.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/static/fonts/dm-sans.ts\",\"import\":\"DM_Sans\",\"arguments\":[{\"style\":[\"normal\",\"italic\"],\"display\":\"swap\",\"subsets\":[\"latin\",\"latin-ext\"],\"fallback\":[\"Roboto\",\"Helvetica\",\"sans-serif\"]}],\"variableName\":\"dmSans\"}")